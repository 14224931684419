import React from "react";
import PropTypes from "prop-types";
import RenderAssumption from "../../components/RenderAssumption/RenderAssumption"
import ChargingRangeGraph from "../CostsCharts/ChargingRangeGraph"

const SpeedOfCharge = ({ cars, title }) => {

  if (!cars || cars.length === 0) return null;

  const defaultTitle = `Speed Of Charge`;

  const level2ChargingSpeed = {
      title: "Level 2 Charger Speed",
      valueAsFormatted: "7.7 kW"
  }

  const fastChargingSpeed = {
    title: "Fast Charging Speed",
    valueAsFormatted: "50 kW"
  }

  return (
    <section className="container">
      <div className="SpeedOfCharge" id="SpeedOfCharge">
        <div className="row">
          <div className="col-sm-12">
            <p className="h1 text-left">{title || defaultTitle}</p>
          </div>
        </div>
      </div>
      <div className="row">
        <div className="col-md-4 col-lg-3" style={{ textAlign: "left" }}>
          <div className="input-well d-none d-md-block assumptions-area">
            <p className="RenderItemTitle" style={{ marginBottom: 10 }}>
              Assumptions
            </p>
            <RenderAssumption assumption={level2ChargingSpeed} />
            <RenderAssumption assumption={fastChargingSpeed} />
          </div>
        </div>
        <div
          id="costs-chart-areas"
          aria-live="polite"
          aria-atomic="true"
          role="region"
          style={{ position: "absolute", top: "-9999px" }}
        >
          <span>
            Results have been updated as of {new Date().toLocaleString()}.
          </span>
        </div>
        <div className="col-md-8 col-lg-9">
          <ChargingRangeGraph cars={cars} chargerLevel={"dc_fast_charger"}/>
          <br />
          <ChargingRangeGraph cars={cars} chargerLevel={"level_2"}/>
        </div>
      </div>
    </section>
  );
};

export default SpeedOfCharge;

SpeedOfCharge.propTypes = {
  cars: PropTypes.array,
  title: PropTypes.string
};
