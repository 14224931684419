import React, { useState } from "react";
import PropTypes from "prop-types";
import arrow from "../../../assets/images/icons/arrow.svg";
import {
  business_secondary,
  residential_secondary,
  electric_vehicles_secondary,
  about_secondary,
} from "./HeaderTabs";

import { Collapse, Navbar, NavbarToggler, Nav, NavItem } from "reactstrap";

import logo from "../../../assets/images/energy-right-logo.svg";
import "./Header.scss";
import { FormattedMessage } from "react-intl";

const Header = ({ page }) => {
  const [collapse, setCollapse] = useState(false);
  function secondaryOnMobile(event) {
    let secondaryId = event.target.id;
    if (!secondaryId) {
      secondaryId = event.target.parentElement.id;
    }

    const tertiarySecondaryElements =
      event.target.parentElement.parentElement.parentElement.querySelectorAll(
        ".target-mobile",
        ".mega-nav-secondary-list-item"
      );


    for (const element of tertiarySecondaryElements) {
      secondaryId !== element.id
        ? element.classList.remove("active")
        : element.classList.contains("active")
        ? element.classList.remove("active")
        : element.classList.add("active");
    }
  }

  function secondaryOn(event) {
    const hasRef = !!event.target.rel;

    let secondaryId = event.target.id;
    if (!secondaryId) {
      secondaryId = event.target.parentElement.id;
    }

    const tertiaryElements = hasRef
      ? event.target.parentElement.parentElement.parentElement.parentElement.querySelectorAll(
          ".secondary-target"
        )
      : event.target.parentElement.parentElement.parentElement.querySelectorAll(
          ".secondary-target"
        );

    for (const element of tertiaryElements) {
      if (secondaryId !== element.id) {
        element.classList.add("hidden") 
        element.classList.remove("flex")
      } else {
        element.classList.add("flex")
        element.classList.remove("hidden")
      }
    }

    const secondaryElements = hasRef
      ? event.target.parentElement.parentElement.parentElement.querySelectorAll(
          ".mega-nav-secondary-list-item"
        )
      : event.target.parentElement.parentElement.querySelectorAll(
          ".mega-nav-secondary-list-item"
        );

    for (const element of secondaryElements) {
      secondaryId !== element.id
        ? element.classList.remove("selected")
        : element.classList.contains("selected")
        ? element.classList.remove("selected")
        : element.classList.add("selected");
    }
  }

  function primaryOn(event) {
    const primaryId = event.target.id;
    const secondaryElements = document.querySelectorAll(
      ".container-sub-list,.navigation-container"
    );
    for (const element of secondaryElements) {
       if (primaryId !== element.id) {
         element.classList.add("hidden");
         element.classList.remove("block", "flex");
       } else if (element.classList.contains("navigation-container")) {
         element.classList.add("block");
         element.classList.remove("hidden");
       } else {
        element.classList.add("flex");
        element.classList.remove("hidden");
       }
    }
  }

  const SecondaryNav = ({ link, title, description, tab, key, ele }) => (
    <>
      <li
        key={key}
        className={"mega-nav-secondary-list-item mobile"}
        onClick={secondaryOnMobile}
        id={tab}
      >
        <a href={link} className="non-click" target="_blank" rel="noopener noreferrer">
          <div className="mega-nav-secondary-list-item-title">{title}</div>
          <div className="mega-nav-secondary-list-item-description">
            {description}
          </div>
        </a>
        <img id={tab} className="right-arrow" alt="arrowTop" src={arrow} />
      </li>
      <li
        key={key}
        className={"mega-nav-secondary-list-item desktop"}
        onMouseEnter={secondaryOn}
        id={tab}
      >
        <a href={link} target="_blank" rel="noopener noreferrer">
          <div className="mega-nav-secondary-list-item-title">{title}</div>
          <div className="mega-nav-secondary-list-item-description">
            {description}
          </div>
        </a>
        <img id={tab} className="right-arrow" alt="arrowTop" src={arrow} />
      </li>
      <ul
        className="mega-nav-tertiary-list target-mobile"
        id={ele.tab}
        key={key}
      >
        {ele.tertiary_elements.map((ter, j) => {
          return (
            <TertiaryNav
              key={`${key} ${j}`}
              link={ter.link}
              title={ter.title}
              description={ter.description}
              tab={ele.tab}
            />
          );
        })}
      </ul>
    </>
  );

  const TertiaryNav = ({ link, title, description, tab }) => (
    <li id={tab + "tertiary"} className="mega-nav-tertiary-list-item">
      <a href={link} target="_blank" rel="noopener noreferrer">
        <div className="mega-nav-tertiary-list-item-title">{title}</div>
        <div className="mega-nav-tertiary-list-item-description">
          {description}
        </div>
      </a>
    </li>
  );

  const NavSub = ({ secondary_elements, id }) => {
    return (
      <div id={id} className="container-sub-list">
        <ul className="mega-nav-secondary-list" id={id}>
          {secondary_elements.map((ele, i) => (
            <SecondaryNav
              key={i}
              position={i}
              link={ele.link}
              title={ele.title}
              description={ele.description}
              tab={ele.tab}
              ele={ele}
            />
          ))}
        </ul>
        {secondary_elements.map((ele, i) => {
          return (
            <ul
              className="mega-nav-tertiary-list secondary-target"
              id={ele.tab}
              key={i}
            >
              {ele.tertiary_elements.map((ter, j) => {
                return (
                  <TertiaryNav
                    key={`${i} ${j}`}
                    link={ter.link}
                    title={ter.title}
                    description={ter.description}
                    tab={ele.tab}
                  />
                );
              })}
            </ul>
          );
        })}
      </div>
    );
  };

  return (
    <div className="Header">
      <div className="main-nav">
        <div className="container">
          <Navbar expand="lg">
            <div className="collapseContainer">
              <div className="logo">
                <a
                  href="https://energyright.com/"
                  // target="_blank"
                  // rel="noopener noreferrer"
                  className="navbar-brand"
                >
                  <img
                    src={logo}
                    alt="{process.env.REACT_APP_FULL_COMPANY_NAME}"
                    className="img-fluid"
                  />
                </a>
              </div>
              <NavbarToggler onClick={() => setCollapse(!collapse)} aria-label={collapse ? 'Close navigation' : 'Open navigation'} />
            </div>
            <Collapse isOpen={collapse} navbar>
              <Nav className="" navbar>
                {process.env.REACT_APP_PAGES_CUSTOM_1_ENABLED ? (
                  <NavItem onMouseEnter={primaryOn} id="business">
                    <a
                      href={process.env.REACT_APP_PAGES_CUSTOM_1_NAVLINK}
                      title={
                        page === process.env.REACT_APP_PAGES_CUSTOM_1_NAVLINK
                          ? "Active Page"
                          : null
                      }
                      className="nav-item hoverBottom"
                      id="business"
                    >
                      <span id="business">
                        {process.env.REACT_APP_PAGES_CUSTOM_1_NAVNAME}
                      </span>
                    </a>{" "}
                    <img
                      id="business"
                      className="left-arrow"
                      alt="arrowTop"
                      src={arrow}
                      onClick={primaryOn}
                    />
                    {
                      <div className="navigation-mega-menu">
                        <div id="business" className="navigation-container">
                          <div className="navigation-header">
                            <img
                              className="left-arrow"
                              alt="arrowTop"
                              src={arrow}
                              onClick={primaryOn}
                            />
                            <header className="mega-nav-title-bar">
                              <div className="mega-nav-title">
                                <FormattedMessage
                                  id="header.business"
                                  defaultMessage="Business & Industry"
                                  description="Business Header Title"
                                />
                              </div>
                            </header>
                          </div>
                          <NavSub
                            id={"business"}
                            secondary_elements={business_secondary}
                          />
                        </div>
                      </div>
                    }
                  </NavItem>
                ) : null}

                {process.env.REACT_APP_PAGES_CUSTOM_2_ENABLED ? (
                  <NavItem onMouseEnter={primaryOn} id="residential">
                    <a
                      href={process.env.REACT_APP_PAGES_CUSTOM_2_NAVLINK}
                      title={
                        page === process.env.REACT_APP_PAGES_CUSTOM_2_NAVLINK
                          ? "Active Page"
                          : null
                      }
                      className="nav-item hoverBottom"
                      id="residential"
                    >
                      <span id="residential">
                        {process.env.REACT_APP_PAGES_CUSTOM_2_NAVNAME}
                      </span>
                    </a>
                    <img
                      id="residential"
                      className="left-arrow"
                      alt="arrowTop"
                      src={arrow}
                      onClick={primaryOn}
                    />
                    {
                      <div className="navigation-mega-menu">
                        <div id="residential" className="navigation-container">
                          <div className="navigation-header">
                            <img
                              className="left-arrow"
                              alt="arrowTop"
                              src={arrow}
                              onClick={primaryOn}
                            />
                            <header className="mega-nav-title-bar">
                              <div className="mega-nav-title">
                                <FormattedMessage
                                  id="header.residential"
                                  defaultMessage="Residential"
                                  description="Residential Header Title"
                                />
                              </div>
                            </header>
                          </div>
                          <NavSub
                            id={"residential"}
                            secondary_elements={residential_secondary}
                          />
                        </div>
                      </div>
                    }
                  </NavItem>
                ) : null}
                {process.env.REACT_APP_PAGES_CUSTOM_3_ENABLED ? (
                  <NavItem onMouseEnter={primaryOn} id="electricVehicles">
                    <a
                      href={process.env.REACT_APP_PAGES_CUSTOM_3_NAVLINK}
                      title={
                        page === process.env.REACT_APP_PAGES_CUSTOM_3_NAVLINK
                          ? "Active Page"
                          : null
                      }
                      className="nav-item hoverBottom"
                      id="electricVehicles"
                    >
                      <span id="electricVehicles">
                        {process.env.REACT_APP_PAGES_CUSTOM_3_NAVNAME}
                      </span>
                    </a>
                    <img
                      id="electricVehicles"
                      className="left-arrow"
                      alt="arrowTop"
                      src={arrow}
                      onClick={primaryOn}
                    />
                    {
                      <div className="navigation-mega-menu">
                        <div
                          id="electricVehicles"
                          className="navigation-container"
                        >
                          <div className="navigation-header">
                            <img
                              className="left-arrow"
                              alt="arrowTop"
                              src={arrow}
                              onClick={primaryOn}
                            />
                            <header className="mega-nav-title-bar">
                              <div className="mega-nav-title">
                                <FormattedMessage
                                  id="header.electricVehicles"
                                  defaultMessage="Electric Vehicles"
                                  description="Electric Vehicles Header Title"
                                />
                              </div>
                            </header>
                          </div>
                          <NavSub
                            id={"electricVehicles"}
                            secondary_elements={electric_vehicles_secondary}
                          />
                        </div>
                      </div>
                    }
                  </NavItem>
                ) : null}
                {process.env.REACT_APP_PAGES_CUSTOM_4_ENABLED ? (
                  <NavItem onMouseEnter={primaryOn} id="about">
                    <a
                      href={process.env.REACT_APP_PAGES_CUSTOM_4_NAVLINK}
                      title={
                        page === process.env.REACT_APP_PAGES_CUSTOM_4_NAVLINK
                          ? "Active Page"
                          : null
                      }
                      className="nav-item hoverBottom"
                      id="about"
                    >
                      <span id="about">
                        {process.env.REACT_APP_PAGES_CUSTOM_4_NAVNAME}
                      </span>
                    </a>
                    <img
                      id="about"
                      className="left-arrow"
                      alt="arrowTop"
                      src={arrow}
                      onClick={primaryOn}
                    />
                    {
                      <div className="navigation-mega-menu">
                        <div id="about" className="navigation-container">
                          <div className="navigation-header">
                            <img
                              className="left-arrow"
                              alt="arrowTop"
                              src={arrow}
                              onClick={primaryOn}
                            />
                            <header className="mega-nav-title-bar">
                              <div className="mega-nav-title">
                                <FormattedMessage
                                  id="header.about"
                                  defaultMessage="About EnergyRight"
                                  description="About Header Title"
                                />
                              </div>
                            </header>
                          </div>
                          <NavSub
                            id={"about"}
                            secondary_elements={about_secondary}
                          />
                        </div>
                      </div>
                    }
                  </NavItem>
                ) : null}
              </Nav>
            </Collapse>
          </Navbar>
        </div>
      </div>
    </div>
  );
};

export default Header;

Header.propTypes = {
  page: PropTypes.string,
};
