import React, { useState, useContext } from "react";
import PropTypes from "prop-types";
import UserPrefsContext from "../../../context/UserPrefs/UserPrefsContext";

import Select from "../../shared/InputElements/Select";

import { Modal, ModalBody, ModalHeader } from "reactstrap";
import ChargingAvailabilityWizard from "../../ChargingAvailabilityWizard/ChargingAvailabilityWizard";
import { FormattedMessage, useIntl} from 'react-intl';

const SelectChargerLevel = ({
  id = "select-charger-level",
  ...rest
}) => {
  const intl = useIntl()
  const [showModal, setShowModal] = useState(false);
  const userPrefs = useContext(UserPrefsContext);
  const label = intl.formatMessage ? intl.formatMessage({ id: "evs.homeChargingAvailability", defaultMessage: "Home Charging Availability"}) : "Home Charging Availability"

  return (
    <>
      <Select
        id={id}
        value={userPrefs.get("chargerLevel")}
        label={label}
        optionNames={[
          intl.formatMessage ? intl.formatMessage({ id: "evs.noCharging", defaultMessage: "No Charging"}) : "Can’t charge at home", 
          intl.formatMessage ? intl.formatMessage({ id: "evs.levelOne", defaultMessage: "Level 1"}) : "Only have access to a standard electrical outlet", 
          intl.formatMessage ? intl.formatMessage({ id: "evs.levelTwo", defaultMessage: "Level 2"}) : "Able to install a dedicated charger", ]}
        optionValues={["no_charger", "level_1", "level_2"]}
        ariaControls="electric-vehicles-catalog"
        tooltip={intl.formatMessage ? intl.formatMessage({ id: "evs.homeChargingAvailabilityTooltip", defaultMessage: "More electric vehicles will be convenient for you if you can charge quickly at home."}) : "More electric vehicles will be convenient for you if you can charge quickly at home."}
        hasSupportingInfoBelow
        handler={e => userPrefs.set({ chargerLevel: e.target.value })}
        {...rest}
      />
      <button
        type="button"
        onClick={() => setShowModal(!showModal)}
        className="btn btn-sm"
      >
        <FormattedMessage 
          id="evs.helpMeChoose"
          defaultMessage="Help Me Choose"
          description="Help Me Choose"
        />
      </button>
      <Modal isOpen={showModal} toggle={() => setShowModal(!showModal)}>
        <ModalHeader toggle={() => setShowModal(!showModal)}>
          <FormattedMessage 
            id="evs.chargingAvailability"
            defaultMessage="Charging Availability"
            description="Charging Availability"
          />
        </ModalHeader>
        <ModalBody>
          <ChargingAvailabilityWizard toggle={() => setShowModal(!showModal)} />
        </ModalBody>
      </Modal>
    </>
  );
};

export default SelectChargerLevel;

SelectChargerLevel.propTypes = {
  id: PropTypes.string,
  label: PropTypes.string,
  tooltip: PropTypes.string
};
