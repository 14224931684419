import React from 'react'

import { Link } from "react-router-dom";
import "./NewUsedEVFilter.scss";
import { FormattedMessage } from "react-intl";

const NewUsedEVFilter = ({ value }) => {

    return (
        <div className="input-well">
            <div id="VehicleTypeFilter" className="filter-btns">
                <Link to={`/explore-evs`}>
                    <button
                        className={"ev-filter-btn first " + (value === "new" ? "buttonActiveStyle" : "buttonInactiveStyle")}
                        value="new">
                        <FormattedMessage
                            id="vehicles.new"
                            defaultMessage="New"
                            description="New Option"
                        />
                    </button>
                </Link>
                <Link to={`/used-vehicles`}>
                    <button
                        className={"ev-filter-btn middle " + (value === "used" ? "buttonActiveStyle" : "buttonInactiveStyle")}
                        value="used">
                        <FormattedMessage
                            id="vehicles.used"
                            defaultMessage="Used"
                            description="Used Option"
                        />
                    </button>
                </Link>
                <Link to={`/upcoming-vehicles`}>
                    <button
                        className={"ev-filter-btn bottom " + (value === "upcoming" ? "buttonActiveStyle" : "buttonInactiveStyle")}
                        value="upcoming">
                        <FormattedMessage
                            id="vehicles.upcoming"
                            defaultMessage="Upcoming"
                            description="Upcoming Option"
                        />
                    </button>
                </Link>
            </div>
        </div>
    )
}
export default NewUsedEVFilter