import  { useEffect } from 'react';

const InjectScript = () => {
  useEffect(() => {
    if (process.env.REACT_APP_ENVIRONMENT === 'production') {
      const script = document.createElement('script');
      script.async = true;
      script.type = 'text/javascript';
      script.src = 'https://dap.digitalgov.gov/Universal-Federated-Analytics-Min.js?agency=TVA';
      script.id = '_fed_an_ua_tag';
      document.head.appendChild(script);
    }
  }, []);

  return null;
};

export default InjectScript;
