import React, { useContext, useEffect } from "react";
import PropTypes from "prop-types";

import "./UpcomingEV.scss";

import UserPrefsContext from "../../context/UserPrefs/UserPrefsContext";
import UpcomingEVJumbotron from "../../components/UpcomingEVJumbotron/UpcomingEVJumbotron";
import getPaymentDetails from "../../functions/vehicle/getPaymentDetails";

import LoadingSpinner from "../../components/LoadingSpinner/LoadingSpinner";
import { FormatCarName } from "../../utils/Helpers/Format";
import VehicleCarousel from "../../components/VehicleCarousel/VehicleCarousel";

const UpcomingEV = ({ match, upcomingElectricVehicles }) => {
  const userPrefs = useContext(UserPrefsContext);

  let car = null;
  let paymentDetails = {};

  if (upcomingElectricVehicles) {
    const evId = match ? match.params["evId"] : 0;
    car = upcomingElectricVehicles.find((ev) => ev.handle === evId);
    paymentDetails = getPaymentDetails(
      car,
      userPrefs.get("monthsOfOwnership"),
      userPrefs.get("interestRateAsBasisPoints"),
      userPrefs.get("milesDrivenAnnually"),
      userPrefs.get("salesTax")
    );
  }

  useEffect(() => {
    document.title = car ? `${FormatCarName(car)} | GreenCars` : "";
  });

  const renderEV = upcomingElectricVehicles ? (
    <>
      <UpcomingEVJumbotron
        car={car}
        incentives={car.incentives}
        paymentDetails={paymentDetails}
      />

      {process.env.REACT_APP_PAGES_VEHICLE_CAROUSEL ? (
        <VehicleCarousel electricVehicles={upcomingElectricVehicles} />
      ) : null}
    </>
  ) : (
    <div className="container text-center">
      <LoadingSpinner />
    </div>
  );

  return <>{renderEV}</>;
};

export default UpcomingEV;

UpcomingEV.propTypes = {
  match: PropTypes.object,
  greenCars: PropTypes.object,
  upcomingElectricVehicles: PropTypes.array,
};
