import React from "react";
import PropTypes from "prop-types";

import ChargingPinPublic from "./../../../client_customizations/assets/images/icons/StarMarker.svg";
import ChargingPinHighPower from "./../../../client_customizations/assets/images/icons/BoltMarker.svg";
import ChargingPinDefault from "./../../../client_customizations/assets/images/icons/PlugMarker.svg";
import isSuperChargingStation from '../../../utils/predicates/isSuperChargingStation';

import "./ChargingStationsMapLegend.scss";


const ChargingStationsMapLegend = ({ chargingStations }) => {
  const hasSuperChargerStations =
    (chargingStations || []).some(station => isSuperChargingStation(station));

  return (
    <div className="legend ChargingStationsMapLegend">
      <div className="charger-type">
        <b>Public stations</b>
        <br />
        <span>Installed by business or government</span>
        <img src={ChargingPinPublic} alt="" />
      </div>
      {hasSuperChargerStations && (
        <div className="charger-type">
          <b>High Power Stations</b>
          <br />
          <span>DC fast charge or superchargers</span>
          <img src={ChargingPinHighPower} alt="" />
        </div>
      )}
      <div className="charger-type">
        <b>Other Types of Stations</b>
        <br />
        <span>Private stations</span>
        <img src={ChargingPinDefault} alt="" />
      </div>
    </div>
  );
};

ChargingStationsMapLegend.propTypes = {
  chargingStations: PropTypes.array
};

ChargingStationsMapLegend.defaultProps = {
  chargingStations: []
};

export default ChargingStationsMapLegend;
