import React, { useContext } from "react";
import PropTypes from "prop-types";
import UserPrefsContext from "../../context/UserPrefs/UserPrefsContext";
import "./UpcomingEVCard.scss";

import VehicleImage from "../VehicleImage/VehicleImage";

import getPaymentDetails from "../../functions/vehicle/getPaymentDetails";
import {
  FormatAsDollars,
  FormatCarModelAndTrim,
} from "../../utils/Helpers/Format";

import IconBEV from "./../../client_customizations/assets/images/icons/icon-electric.svg";
import IconPHEV from "./../../client_customizations/assets/images/icons/icon-hybrid.svg";
import isBEV from "../../functions/vehicle/isBEV";
import isPHEV from "../../functions/vehicle/isPHEV";
import { FormattedMessage} from "react-intl";

const UpcomingEVCard = ({
  ev,
  hasEvDetails,
}) => {
  const userPrefs = useContext(UserPrefsContext);

  if (!ev) return null;

  let evImgs = ev.images
    ? ev.images.map((n) =>
        n.url_thumbnail == null ? [] : n.url_full == null ? [] : [n]
      )
    : [];

  //Flatten evImgs into a single array
  const evImgSrc = [].concat.apply([], evImgs);

  const imgSrc = ev.images ? (ev.images.length >= 0 ? evImgSrc[0] : "") : "";
  const release_date = ev.model_year ? ev.model_year : "N/A";
  const paymentDetails = getPaymentDetails(
    ev,
    userPrefs.get("monthsOfOwnership"),
    userPrefs.get("interestRateAsBasisPoints"),
    userPrefs.get("milesDrivenAnnually"),
    userPrefs.get("salesTax")
  );
  const afterIncentives = paymentDetails.afterIncentives
    ? paymentDetails.afterIncentives
    : 0;

  const renderFuelTypeBadge = isBEV(ev) ? (
    <span className="badge-fuel-type">
      <img alt="All-Electric" src={IconBEV} />
    </span>
  ) : isPHEV(ev) ? (
    <span className="badge-fuel-type">
      <img alt="Hybrid" src={IconPHEV} />
    </span>
  ) : null;

  let altText =
    "Go to " + (ev.make + " " + ev.model + " " + ev.trim).toString().trim();

  const renderEVDetails = hasEvDetails ? (
    <div className="renderRowOfData">
      <p>
        <small>
          <FormattedMessage
            id="evCard.releaseDate"
            defaultMessage="Estimated Release Date"
            description="Estimated Release Date"
          />
        </small>
        <span>{release_date}</span>
      </p>
      <p>
        <small>
          <FormattedMessage
            id="evCard.estimatedPrice"
            defaultMessage="Estimated Price"
            description="Estimated Price"
          />
        </small>
        <span>{FormatAsDollars(afterIncentives)}</span>
      </p>
    </div>
  ) : null;

  return (
    <>
      <div className="EVCardTop">
        {renderFuelTypeBadge}
        <p className="h2" style={{ maxWidth: "70%" }}>
          {ev.make}
        </p>
        <p className="h3 mt-1 nowrap">{FormatCarModelAndTrim(ev)}</p>
        <div className="text-center top-margin">
          <VehicleImage image={imgSrc} size="thumb" alt={altText} />
        </div>
        {renderEVDetails}
      </div>
    </>
  );
};

export default UpcomingEVCard;

UpcomingEVCard.propTypes = {
  ev: PropTypes.object,
  hasEvDetails: PropTypes.bool,
  hasIncentivesAndMatchScore: PropTypes.bool,
  hasLinkToEv: PropTypes.bool,
  hasLocallyAvailableHidden: PropTypes.bool,
};
