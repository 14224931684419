import React, { useState } from "react";
import PropTypes from "prop-types";

import { FormattedMessage } from "react-intl";
import EVCardCarousel from "../../components/EVCardCarousel/EVCardCarousel";
import LeftArrow from "../../client_customizations/assets/images/icons/left-arrow.svg";
import RightArrow from "../../client_customizations/assets/images/icons/right-arrow.svg";
import "./VehicleCarousel.scss";
import { NavLink } from "react-router-dom";

const VehicleCarousel = ({ electricVehicles }) => {
  let size = window.innerWidth > 767;

  // console.log(Math.floor(electricVehicles.length * Math.random()))
  // console.log(Math.floor(electricVehicles.length))
  const [firstEv, setFirstEv] = useState(0);

  if (!electricVehicles || electricVehicles.length <= 2) return null;

  const vehicleCount = electricVehicles.length;
  const maxIndex = vehicleCount - 1;

  const evIndices = [
    firstEv,
    firstEv + 1 <= maxIndex ? firstEv + 1 : firstEv + 1 - vehicleCount,
    firstEv + 2 <= maxIndex ? firstEv + 2 : firstEv + 2 - vehicleCount,
  ];

  const filteredEVs = [
    electricVehicles[evIndices[0]],
    electricVehicles[evIndices[1]],
    electricVehicles[evIndices[2]],
  ];

  const selectableEvs = filteredEVs.map((ev, i) => {
    return (
      <NavLink
        className={`${"evc-card EVCard carousel-wider"}`}
        to={`/upcoming-vehicles/${ev.handle}`}
      >
        <div tabIndex="0" key={ev.electric_vehicle_id}>
          <EVCardCarousel ev={ev} />
        </div>
      </NavLink>
    );
  });
  const mobileSelectableEvs = (
    <NavLink
      className={`${"evc-card EVCard carousel-wider"}`}
      to={`/upcoming-vehicles/${filteredEVs[0].handle}`}
    >
      <div tabIndex="0" key={0}>
        <EVCardCarousel ev={filteredEVs[0]} />
      </div>
    </NavLink>
  );
  const renderVehicles = (
    <>
      <div
        id="vehicleRender"
        className={
          size
            ? "carousel-row d-md-flex d-xl-flex"
            : "mobile-carousel-row d-md-flex d-xl-flex"
        }
      >
        <div className={size ? "col-sm-1" : "right col-sm-1"}>
          <div
            onClick={() =>
              firstEv === 0 ? setFirstEv(0) : setFirstEv(firstEv - 1)
            }
            className="button-box"
          >
            <img className="left-arrow" src={LeftArrow} alt="left arrow" />
          </div>
        </div>
        <div className="col-sm-10">
          <div className="render-cards-container full-width">
            {size ? selectableEvs : mobileSelectableEvs}
          </div>
        </div>
        <div className="col-sm-1">
          <div
            onClick={() =>
              firstEv === maxIndex ? null : setFirstEv(firstEv + 1)
            }
            className="button-box"
          >
            <img className="right-arrow" src={RightArrow} alt="right arrow" />
          </div>
        </div>
      </div>
    </>
  );

  return (
    <section className="tab-section active" id="HomepageVehiclesCarousel">
      <div className="tab-header">
        <div className="container">
          <div className="tab-content">
            <h1 style={{ color: "black", fontSize: "30px" }}>
              <FormattedMessage
                id="homepage.vehicleCarousel.title"
                defaultMessage="Upcoming Vehicles"
                description="Vehicle Carousel Title"
              />
            </h1>
            <div style={{ marginTop: 25, marginBottom: 25 }}>
              <FormattedMessage
                id="homepage.vehicleCarousel.subTitle"
                defaultMessage="Compare electric cars by EV range, price, or your personalized Match Score. Click on the EV for more details."
                description="Vehicle Carousel SubTitle"
              />
            </div>
          </div>
        </div>
      </div>
      <div className="container">
        <div className="col-sm-12">{renderVehicles}</div>
      </div>
    </section>
  );
};

export default VehicleCarousel;

VehicleCarousel.propTypes = {
  electricVehicles: PropTypes.array,
};
