import React from "react";
import PropTypes from "prop-types";
import "./UpcomingEVPricePanel.scss";

import { TabContent, TabPane } from "reactstrap";
import { FormatAsDollars } from "../../utils/Helpers/Format";

import { FormattedMessage } from "react-intl";

const UpcomingPricePanel = ({ car, paymentDetails }) => {
  if (!paymentDetails) return null;
  return (
    <>
      <div className="PricePanels">
        <TabContent>
          <TabPane>
            <div className="row">
              <div className="col-sm-12">
                <div className="PanelItem text-center bottom-margin-large">
                  <div className="PanelItemTop">
                    <p className="RenderItemTitle bottom-margin-small">
                      <FormattedMessage
                        id="vehicle.estimatedMsrp"
                        defaultMessage="Estimated MSRP"
                        description="Price Panel Estimated MSRP"
                      />
                    </p>
                    <p className="h1">{FormatAsDollars(paymentDetails.msrp)}</p>
                  </div>
                </div>
                <div className="text-center">
                  <div className="inventory">
                    <a
                      href={`/upcoming-vehicles`}
                      rel="noopener noreferrer"
                    >
                      <button className="button">
                        <FormattedMessage
                          id="ev.jumbotron.viewAllCars"
                          defaultMessage="View all cars"
                          description="View all cars"
                        />
                      </button>
                    </a>
                  </div>
                </div>
                {/* <div className="text-center">
                  <div className="update">
                    <a
                      href="https://www.greencars.com/blog#subscribe"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      <button className="button">
                        <FormattedMessage
                          id="vehicle.updated"
                          defaultMessage="Keep me Updated"
                          description="Price Panel Keep me Updated"
                        />
                      </button>
                    </a>
                  </div>
                </div> */}
              </div>
            </div>
          </TabPane>
        </TabContent>
      </div>
    </>
  );
};

export default UpcomingPricePanel;

UpcomingPricePanel.propTypes = {
  car: PropTypes.object,
  paymentDetails: PropTypes.object,
};
