import React from "react";
import PropTypes from "prop-types";
import { FormatAsTime } from "../../utils/Helpers/Format";
import ToolTip from "../../components/shared/ToolTip/ToolTip";
import { useIntl } from "react-intl";

import "./UpcomingCarDetails.scss";
import calcCharging from "../../functions/vehicle/Charging/calcCharging";

const UpcomingCarDetails = ({ car }) => {
  const intl = useIntl();

  return (
    <div id="carDetailsGrid">
      <div className="gridTitle">Vehicle Info</div>
      <div className="grid">
        <div className="row">
          <div className="column grey title">Type</div>
          <div className="column white bordered data">{car["make"]}</div>
          <div className="column grey bordered title">
            Estimated Total Range
          </div>
          <div className="column white data">
            {car["total_range"] + " miles"}
          </div>
        </div>
        <div className="row">
          <div className="column grey title">Seats</div>
          <div className="column white bordered data">{car["seats_min"]}</div>
          <div className="column grey bordered title flex">
            Estimated Time to Charge - Level 2
            <ToolTip
              id={"time_to_charge"}
              message={"Level 2 Charger Speed: 7.7kW"}
              upcoming={true}
            />
          </div>
          <div className="column white data">
            {car.ac_charging_power === 0
              ? "Coming Soon"
              : "~ " +
                FormatAsTime(
                  calcCharging.timeForFullBattery(
                    car.battery_capacity,
                    car.ac_charging_power,
                    "level_2"
                  )
                )}
          </div>
        </div>
        <div className="row">
          <div className="column grey title">Fuel Type</div>
          <div className="column white bordered data">{car["fuel"]}</div>
          <div className="column grey bordered title flex">
            Estimated Battery Size
            <ToolTip
              id={"estimated_battery_size"}
              message={intl.formatMessage({
                id: "ev.carDetails.usedBatterySizeTooltip",
                defaultMessage:
                  "One kilowatt-hour (kWh) is enough energy to drive 1 to 4 miles, depending on the vehicle. To account for battery degradation for a used EV, the battery size quoted here is an estimation based on the age of the vehicle and normal driving/charging patterns.",
              })}
              upcoming={true}
            />
          </div>
          <div className="column white data">
            {car["battery_capacity"] + " kWh"}
          </div>
        </div>
        <div className="row">
          <div className="column grey title">Estimated Electric Range</div>
          <div className="column white bordered data">
            {car["electric_range"] + " miles"}
          </div>
          <div className="column grey bordered"></div>
          <div className="column white"></div>
        </div>
      </div>
    </div>
  );
};

export default UpcomingCarDetails;

UpcomingCarDetails.propTypes = {
  car: PropTypes.object,
};
