import React, { useContext, useEffect } from "react";
import PropTypes from "prop-types";
import UserPrefsContext from "../../context/UserPrefs/UserPrefsContext";
import EVIncentives from "./../../components/EVIncentives/EVIncentives";
import EVJumbotron from "./../../components/EVJumbotron/EVJumbotron";
// import EVReviews from "./../../components/EVReviews/EVReviews";
import useMapTabs from '../../hooks/useMapTabs';
import TabbedMaps from '../../components/TabbedMaps/TabbedMaps';
import CostOfOwnership from "./../../components/CostOfOwnership/CostOfOwnership";
import getPaymentDetails from "./../../functions/vehicle/getPaymentDetails";

import LoadingSpinner from "./../../components/LoadingSpinner/LoadingSpinner";
import isPHEV from "../../functions/vehicle/isPHEV";
import { FormatCarName } from "../../utils/Helpers/Format";

import "./EV.scss";

const EV = ({
  history,
  match,
  tabId,
  electricVehicles,
  chargingStations,
  userLocation,
  ip,
  uuid,
  zipcode,
}) => {
  const userPrefs = useContext(UserPrefsContext);

  let car = null;
  let paymentDetails = {};

  if (electricVehicles) {
    const evId = match ? match.params["evId"] : 0;
    car = electricVehicles.find(ev => ev.handle === evId);
    paymentDetails = getPaymentDetails(
      car,
      userPrefs.get("monthsOfOwnership"),
      userPrefs.get("interestRateAsBasisPoints"),
      userPrefs.get("milesDrivenAnnually"),
      userPrefs.get("salesTax")
    );
  }

  useEffect(() => {
    document.title = car
      ? `${FormatCarName(car)} - TVA EnergyRight`
      : "";
  });

  const ignoreSuperchargerStations = car && isPHEV(car);

  const { activeTabId, toggle, findTab } = useMapTabs(tabId);
  const toggleWithHistory = newTabId => {
    const newTab = findTab(newTabId);
    history.push(newTab.url);
    toggle(newTab.id);
  };

  const renderEV = electricVehicles ? (
    <>
      <EVJumbotron
        car={car}
        incentives={car.incentives}
        paymentDetails={paymentDetails}
      />
      <CostOfOwnership cars={[car, car.equivalent_gas_vehicle]} />
      <EVIncentives incentives={car.incentives} car={car} />
      <section id="maps-section">
        <div className="container">
          <h1>Find a charger around the corner or on your way.</h1>
          <TabbedMaps
            toggle={toggleWithHistory}
            activeTabId={activeTabId}
            chargingStations={chargingStations}
            userLocation={userLocation}
            ignoreSuperchargerStations={ignoreSuperchargerStations}
            zipcode={zipcode}
          />
        </div>
      </section>
      {/* <EVReviews reviews={car.video_reviews} /> */}
    </>
  ) : (
      <div className="container text-center">
        <LoadingSpinner />
      </div>
    );


  return (
    <>
     { renderEV }
    </>
  );
};

export default EV;

EV.propTypes = {
  match: PropTypes.object,
  electricVehicles: PropTypes.array,
  userLocation: PropTypes.object,
  ip: PropTypes.string,
  uuid: PropTypes.string
};
