import React from "react";
import PropTypes from "prop-types";
import "./EVCardCarousel.scss";

import VehicleImage from "../VehicleImage/VehicleImage";

import {
  FormatAsDollars,
  FormatCarModelAndTrim,
} from "../../utils/Helpers/Format";

import IconBEV from "../../client_customizations/assets/images/icons/icon-electric.svg";
import IconPHEV from "../../client_customizations/assets/images/icons/icon-hybrid.svg";
import isBEV from "../../functions/vehicle/isBEV";
import isPHEV from "../../functions/vehicle/isPHEV";

const EVCard = ({ ev }) => {
  if (!ev) return null;

  let evImgs = ev.images
    ? ev.images.map((n) => (n.url_thumbnail && n.url_full ? [n] : []))
    : [];

  //Flatten evImgs into a single array
  const evImgSrc = [].concat.apply([], evImgs);
  const imgSrc = ev.images ? (ev.images.length >= 0 ? evImgSrc[0] : "") : "";
  const msrp = ev.msrp ? ev.msrp : 0;

  let renderFuelTypeBadge;
  switch (isBEV(ev)) {
    case true:
      renderFuelTypeBadge = (
        <span className="badge-fuel-type">
          <img alt="All-Electric" src={IconBEV} />
        </span>
      );
      break;
    case false && isPHEV(ev):
      renderFuelTypeBadge = (
        <span className="badge-fuel-type">
          <img alt="Hybrid" src={IconPHEV} />
        </span>
      );
      break;
    default:
      renderFuelTypeBadge = null;
      break;
  }

  let altText =
    "Go to " + (ev.make + " " + ev.model + " " + ev.trim).toString().trim();

  const renderEVDetails = (
    <div className="renderRowOfData">
      <p>
        <small>Estimated Release Date</small>
        <span>{ev.model_year}</span>
      </p>
      <p>
        <small>Estimated Price</small>
        <span>{FormatAsDollars(msrp)}</span>
      </p>
    </div>
  );

  return (
    <>
      <div className="EVCardTop">
        {renderFuelTypeBadge}
        <p className="h2" style={{ maxWidth: "70%" }}>
          {ev.model_year} {ev.make}
        </p>
        <p className="h3 mt-1">{FormatCarModelAndTrim(ev)}</p>
        <div className="text-center">
          <VehicleImage image={imgSrc} size="thumb" alt={altText} />
        </div>
        {renderEVDetails}
      </div>
    </>
  );
};

export default EVCard;

EVCard.propTypes = {
  ev: PropTypes.object,
};
