import React, { useContext } from "react";
import PropTypes from "prop-types";
import UserPrefsContext from "../../../context/UserPrefs/UserPrefsContext";

import { FormatAsDollars } from "../../../utils/Helpers/Format";

import Range from "../../shared/InputElements/Range";

const descriptionFn = val => {
  return FormatAsDollars(val);
};

const SlideMaxBudget = ({
  id = "max-budget-range",
  label = "Budget",
  description = descriptionFn,
  ...rest
}) => {
  const userPrefs = useContext(UserPrefsContext);

  return (
    <Range
      id={id}
      value={userPrefs.get("maxBudget")}
      label={label}
      rangeMin={15000}
      rangeMax={150000}
      rangeStep={500}
      description={description}
      ariaControls="electric-vehicles-catalog"
      handler={e => userPrefs.set({ maxBudget: e.target.value })}
      hasSupportingInfoBelow
      {...rest}
    />
  );
};

export default SlideMaxBudget;

SlideMaxBudget.propTypes = {
  id: PropTypes.string,
  label: PropTypes.string,
  description: PropTypes.func
};
