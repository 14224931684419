import React from "react";
import PropTypes from "prop-types";
import "./UpcomingEVs.scss";

import UpcomingEVCatalog from "../../components/UpcomingEVCatalog/UpcomingEVCatalog";
import MatchScoreOptions from "../../components/MatchScoreOptions/MatchScoreOptions";
import EvSortControls from "../../components/EvSortControls/EvSortControls";
import EVFilterControls from "../../components/EVFilterControls/EVFilterControls";
import ModalComponent from "../../components/ModalUIComponent/ModalUIComponent";
import NewUsedEVFilter from "../../components/NewUsedEVFilter/NewUsedEvFilter";

import { FormattedMessage, useIntl } from "react-intl";

const UpcomingEVs = ({ upcomingElectricVehicles, ip, uuid, zipcode }) => {
  const intl = useIntl();

  const time = new Date().toLocaleString();

  const renderOptions = (
    <>
      {process.env.REACT_APP_PAGES_EV_FILTER ? (
        <NewUsedEVFilter value={"upcoming"} />
      ) : null}
      <br />
      <MatchScoreOptions value={"upcoming"} zipcode={zipcode} />
      <br />
      <EVFilterControls vehicles={upcomingElectricVehicles} />
      <br />
      <p className="legal-disclaimer">
        Vehicles displayed may not reflect actual availability. GreenCars does
        not endorse or recommend any specific vehicle or car manufacturer.
      </p>
    </>
  );

  return (
    <section className="container ev-page">
      <div className="row mb-3">
        <div className="col-sm-12 text-center title-area">
          <h2>
            <FormattedMessage
              id="vehicles.title"
              defaultMessage="Find an electric vehicle just right for you."
              description="Find an electric vehicle just right for you."
            />
          </h2>
          <p className="lead">
            <FormattedMessage
              id="vehicles.subtitle"
              defaultMessage="Compare electric cars by EV range, price or your personalized match score. Click on the EV for more details, including total cost compared to a similar gas vehicle."
              description="Compare electric cars by EV range, price or your personalized match score. Click on the EV for more details, including total cost compared to a similar gas vehicle."
            />
          </p>
        </div>
      </div>
      <div className="row ev-container-row">
        <div className="col-sm-12 d-block d-lg-none text-center">
          <ModalComponent
            buttonText={
              intl.formatMessage
                ? intl.formatMessage({
                    id: "evs.buttonMatchScoreAndFilters",
                    defaultMessage: "Match Score and Filters",
                  })
                : "Match Score and Filters"
            }
            titleText={
              intl.formatMessage
                ? intl.formatMessage({
                    id: "evs.matchScoreAndFilters",
                    defaultMessage: "Refine Match Score and Filters",
                  })
                : "Refine Match Score and Filters"
            }
          >
            {renderOptions}
          </ModalComponent>
        </div>
        <div className="col-xl-3 col-lg-4 d-none d-lg-block">
          {renderOptions}
        </div>
        <div
          id="electric-vehicles-catalog"
          aria-live="polite"
          aria-atomic="true"
          role="region"
          style={{ position: "absolute", top: "-9999px" }}
        >
          <span>Results have been updated as of {time}.</span>
        </div>
        <div
          className="col-xl-9 col-lg-8 col-sm-12"
          style={{ paddingLeft: 10 }}
        >
          <div className="row sort-by-row">
            <div className="col">
              <div className="pull-right">
                <EvSortControls />
              </div>
            </div>
          </div>
          <UpcomingEVCatalog
            vehicles={upcomingElectricVehicles}
            hasEvDetails
            hasIncentivesAndMatchScore
          />
        </div>
      </div>
    </section>
  );
};

export default UpcomingEVs;

UpcomingEVs.propTypes = {
  upcomingElectricVehicles: PropTypes.array,
  ip: PropTypes.string,
  uuid: PropTypes.string,
};
