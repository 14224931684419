import UsedVehicle from "../utils/Helpers/UsedVehicle"

const fetchElectricVehicles = async params => {
  // Remove null params
  Object.keys(params).forEach(key => params[key] == null && delete params[key]);

  let url = new URL(
    `${process.env.REACT_APP_EV_INFO_API_HOST}/vehicles`
  );

  let searchParams = new URLSearchParams(params);

  url.search = searchParams;

  url.search += `&include_used_vehicles`

  const response = await window.fetch(url, {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      Accept: "application/json",
      Authorization: `Bearer ${process.env.REACT_APP_EV_INFO_TKN}`
    }
  });

  const json = await response.json();
  const electricVehicles = json.vehicles;

  const newElectricVehicles = [];
  const usedElectricVehicles = [];
  let upcomingElectricVehicles = [];
  const vehicleTypeList = { 'Station Wagon': 'Wagon', pickup_truck: 'Truck' };

  electricVehicles.forEach(ev => {
    ev.form_factor = vehicleTypeList[ev.form_factor] ?? ev.form_factor;

    ev.images.forEach(image=>{
      if (image.legal_info_required=== 1){
        image.legal_info=ev.legal_info;
    }})
    
    if (ev.availability === "upcoming") {
      upcomingElectricVehicles.push(ev)
    }
    
    else if (ev.available_for_purchase_as_new) {
      newElectricVehicles.push(ev);
    } else {
      usedElectricVehicles.push(UsedVehicle({ ...ev }));
    }
  });
  return {
    newElectricVehicles: newElectricVehicles,
    usedElectricVehicles: usedElectricVehicles,
    upcomingElectricVehicles: upcomingElectricVehicles,
  };
};

export default fetchElectricVehicles;
