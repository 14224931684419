export const business_secondary = [
  {
    tab: "incentives",
    link: "https://energyright.com/business-industry/incentives/",
    title: "Incentives",
    description: "Incentives for equipment & facility improvements.",
    tertiary_elements: [
      {
        title: "All incentives",
        description:
          "Discover financial incentives to help you reach your energy goals.",
        link: "https://energyright.com/business-industry/incentives/",
      },

      {
        title: "Thermal ice storage",
        description:
          "Offset the cost of thermal ice storage systems & lower your energy costs.",
        link: "https://energyright.com/business-industry/incentives/thermal-storage/",
      },

      {
        title: "Custom",
        description:
          "Receive flexible, tailored incentives for your facility's energy efficiency projects.",
        link: "https://energyright.com/business-industry/incentives/custom-incentive/",
      },

      {
        title: "Electric forklifts",
        description:
          "Lower operating costs & reduce maintenance with electric forklift incentives.",
        link: "https://energyright.com/business-industry/incentives/electric-forklifts/",
      },
      {
        title: "HVAC",
        description: "Discover incentives for more efficient HVAC systems.",
        link: "https://energyright.com/business-industry/incentives/hvac/",
      },
      {
        title: "LED Lighting",
        description:
          "Save on energy & maintenance costs with LED lighting incentives.",
        link: "https://energyright.com/business-industry/incentives/led-lights/",
      },
    ],
  },
  {
    tab: "partners",
    link: "https://energyright.com/business-industry/preferred-partners-network/",
    title: "Connect with a Contractor",
    description: "Find trusted contractors for energy upgrades.",
    tertiary_elements: [
      {
        title: "Request expert solutions",
        description:
          "Submit project requests to multiple contractors for cost estimates & help with incentives.",
        link: "https://energyright.com/business-industry/request-expert-solutions/",
      },
      {
        title: "Become a member",
        description:
          "Join the Preferred Partners Network & support businesses with incentive projects.",
        link: "https://energyright.com/business-industry/become-a-member/",
      },
      {
        title: "Find a contractor",
        description:
          "Select a TVA-vetted profressional from our list of approved, trained providers.",
        link: "https://energyright.com/business-industry/find-a-contractor/",
      },
      {
        title: "Preferred Partners Network member sign-in",
        description:
          "Log in to view work requests, access energy calculation tools & attend online classes.",
        link: "https://ppn.tvaenergyrightsolutions.com/",
      },
    ],
  },
  {
    tab: "resources",
    link: "https://energyright.com/",
    title: "Energy-saving programs",
    description:
      "Energy management programs to help businesses achieve energy goals.",
    tertiary_elements: [
      {
        title: "Save It Forward",
        description: "Helping large industrial customers achieve energy goals.",
        link: "https://energyright.com/business-industry/save-it-forward/",
      },
      {
        title: "Demand Response",
        description: "Earn financial credits for reducing peak demand.",
        link: "https://energyright.com/business-industry/demand-response/",
      },
      {
        title: "School Uplift",
        description: "Grants & efficiency training for public schools.",
        link: "https://energyright.com/business-industry/school-uplift/",
      },
      {
        title: "Small Business Uplift",
        description: "Helping small businesses make energy upgrades.",
        link: "https://energyright.com/business-industry/small-business-uplift/",
      },
      {
        title: "Federal Energy Services Program",
        description: "Energy solutions for federal customers.",
        link: "https://energyright.com/business-industry/fesp/",
      },
    ],
  },
  {
    tab: "additional-programs",
    link: "https://energyright.com/",
    title: "Energy-saving advice & education",
    description: "Tools & resources to helo businesses save on energy costs.",
    tertiary_elements: [
      {
        title: "Understanding your energy costs",
        description: "Learn how to lower costs & achieve energy goals.",
        link: "https://energyright.com/business-industry/understanding-energy-costs/",
      },
      {
        title: "Compressed Air Training",
        description: "Optimize compressed air systems.",
        link: "https://energyright.com/business-industry/compressed-air-training/",
      },
      {
        title: "Carbon reduction",
        description: "Guidance & tools to lower emissions.",
        link: "https://energyright.com/business-industry/carbon-reduction/",
      },
    ],
  },
];

export const residential_secondary = [
  {
    tab: "services",
    link: "https://energyright.com/residential/services/",
    title: "Energy upgrades & bill assistance",
    description:
      "Discover energy-saving products & financial assistance for home energy needs.",
    tertiary_elements: [
      {
        title: "Financing",
        description:
          "Apply for affordable, $0 down financing for home energy upgrades.",
        link: "https://energyright.com/residential/financing/",
      },
      {
        title: "Home Uplift",
        description:
          "See if you qualify for free home energy upgrades from TVA & your local power company.",
        link: "https://energyright.com/residential/home-uplift/",
      },
      {
        title: "EnergyRight Marketplace",
        description:
          "Shop for, compare & buy energy-efficient appliances & products.",
        link: "https://energyright.efficientchoice.com/",
      },
      {
        title: "Energy bill assistance",
        description:
          "Learn more about the Low Income Home Energy Assistance Program in your area.",
        link: "https://energyright.com/residential/energy-assistance/",
      },
    ],
  },
  {
    tab: "residents",
    link: "#",
    title: "Find a contractor",
    description:
      "Find trusted contractors for your home energy upgrades or emergency repairs",
    tertiary_elements: [
      {
        title: "Quality Contractor Network",
        description:
          "Find a TVA-vetted, licensed & insured contractor for your home energy upgrades.",
        link: "https://energyright.com/residential/quality-contractor-network/",
      },
      {
        title: "Become a member",
        description:
          "Join our contractor network to grow your business & become a trusted energy-efficiency resource for your community.",
        link: "https://energyright.com/residential/quality-contractor-network/join/",
      },
      {
        title: "Quick Quote",
        description:
          "Get home energy project quotes from multiple TVA-vetted contractors at the same time",
        link: "https://energyright.com/residential/quick-quote/",
      },
      {
        title: "",
        description: "",
        link: "",
      },
    ],
  },
  {
    tab: "workshops-events",
    link: "https://energyright.com/residential/energy-assistance/",
    title: "Energy-saving advice & education",
    description:
      "Tools, resources & tips to help you save on your energy costs.",
    tertiary_elements: [
      {
        title: "DIY Home Energy Assessment",
        description:
          "Use your mobile device to explore your home's energy use with our free, self-guided assessment.",
        link: "https://energyright.com/residential/services/home-energy-assessment/",
      },
      {
        title: "Ask an Advisor",
        description:
          "Get your home energy questions answered by a TVA-trained Home Energy Advisor.",
        link: "https://energyright.com/residential/ask-an-advisor/",
      },
      {
        title: "Education & advice",
        description:
          "Watch videos & get tips to help you tackle energy-saving DIY projects & upgrades.",
        link: "https://energyright.com/residential/education-advice/",
      },
      {
        title: "Workshops",
        description:
          "Participate in free workshops that teach you how your home uses energy & easy ways to lower energy bills.",
        link: "https://energyright.com/residential/workshops/",
      },
      {
        title: "Home Energy Evaluation",
        description:
          "Uncover customized money-saving recommendations from a Home Energy Advisor.",
        link: "https://energyright.com/residential/services/home-energy-evaluation/",
      },
      {
        title: "FAQ",
        description:
          "Get vour frequently asked questions about TVA EnergyRight & energy efficiency answered.",
        link: "https://energyright.com/residential/faq/",
      },
    ],
  },
];

export const electric_vehicles_secondary = [
  {
    tab: "electric-vehicles",
    link: "https://energyright.com/ev/",
    title: "Electric Vehicles",
    description: "Fast-tracking EVs in the region.",
    tertiary_elements: [
      {
        title: "Explore EVs",
        description: "Find an EV that's right for you.",
        link: "https://ev.energyright.com/explore-evs/",
      },
      {
        title: "Find a Charger",
        description: "Learn about EV charging & find a charger near you.",
        link: "https://energyright.com/ev/charging/",
      },
      {
        title: "Compare EVs",
        description: "Compare vehicles & see how well EVs measure up.",
        link: "https://ev.energyright.com/compare/",
      },
      {
        title: "EV video series",
        description: "Watch In Charge: Life With an Electric Vehicle",
        link: "https://energyright.com/ev/life-with-an-electric-vehicle/",
      },
      {
        title: "Resources",
        description: "Be a part of the growing EV community.",
        link: "https://energyright.com/ev/chargers/",
      },
      {
        title: "Press",
        description: "TVA EV initiative press kit & newsroom.",
        link: "https://energyright.com/ev/press-kit/",
      },
    ],
  },
];

export const about_secondary = [
  {
    tab: "aboutUs",
    link: "#",
    title: "About us",
    description: "Making life better for people in the region",
    tertiary_elements: [
      {
        title: "About TVA",
        description:
          "The Tennessee Valley Authority is committed to improving the quality of life for people we serve.",
        link: "https://www.tva.com/About-TVA",
      },
      {
        title: "Blog: The Current",
        description:
          "Visit our blog for stories from TVA EnergyRight & local power companies in the region.",
        link: "https://energyright.com/thecurrent/",
      },
      {
        title: "VA EnergyRight & TVA Green Highlights Report",
        description:
          "Shining a light on some of TVA EnergyRight's biggest wins.",
        link: "https://highlights.energyright.com/",
      },
      {
        title: "",
        description: "",
        link: "",
      },
    ],
  },
];
