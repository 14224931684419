import React, {useState, useContext, useEffect } from "react";
import {ValidateFiveDigitCode} from "../../../utils/Helpers/USPostalCodeValidator"; 

import UserPrefsContext from "../../../context/UserPrefs/UserPrefsContext";

import SearchIcon from "../../../client_customizations/assets/images/icons/search-icon.svg";

// import { FormattedMessage } from 'react-intl';

const InputZipcodeForChargingMap = ({zipcode}) => {
  const userPrefs = useContext(UserPrefsContext);

  useEffect(() => {
    setWorkingZipcode(zipcode);
  }, [zipcode]);

  const [workingZipcode,setWorkingZipcode] = useState(
    zipcode
  );

  const isInvalid = userPrefs.zipcodeIsNotFound && !userPrefs.zipcodeIsUpdating;

  const id = "input-zipcode-for-charging-map";
  const idAria = id + "-help";


  const handleUpdateButtonPress = (e, input) => {
    if (input && e.key !== "Enter") return

    if(ValidateFiveDigitCode(workingZipcode) && !userPrefs.zipcodeIsUpdating){

      async function asyncCall() {
        await userPrefs.set({ workingZipcode: workingZipcode });
        if (!userPrefs.zipcodeIsUpdating) {
          userPrefs.syncWorkingZipcode();
        }
      }
      asyncCall()
    }
  }

  return (
    <>
      <div className="form-inline">
        <div className="input-group mb-2 mr-sm-2">
          {/* <div className="input-group-prepend">
            <div className="input-group-text">
              <img src={IconZip} alt="" style={{ width: 15 }} />
            </div>
          </div> */}
          <label htmlFor={id} id={idAria}>Zip Code</label>
          <input
            id={id}
            className={isInvalid ? "form-control is-invalid" : "form-control"}
            aria-describedby={idAria}
            value={workingZipcode}
            onChange={e => setWorkingZipcode (e.target.value)}
            onKeyDown={e => handleUpdateButtonPress(e, "input")}
          />
        </div>
        <button
          type="button"
          className="btn btn-default mb-2"
          onClick={() => handleUpdateButtonPress()}
          disabled={userPrefs.zipcodeIsUpdating}
          id="update-zipcode-button"
        >
          <img src={SearchIcon} alt="Search Icon" />
        </button>
      </div>
      {(isInvalid || !ValidateFiveDigitCode( workingZipcode)) && (
        <div className="text-danger small">
          Error: {workingZipcode} is not a valid zipcode
        </div>
      )}
    </>
  );
};

export default InputZipcodeForChargingMap;
