import React from "react";
import PropTypes from "prop-types";

import UpcomingCarDetails from "../../components/UpcomingCarDetails/UpcomingCarDetails";
import VehicleImage from "../VehicleImage/VehicleImage";
import UpcomingPricePanel from "../UpcomingEVPricePanel/UpcomingEVPricePanel";
import { FormatCarName } from "../../utils/Helpers/Format";
import "./UpcomingEVJumbotron.scss";

const UpcomingEVJumbotron = ({ car, paymentDetails }) => {
  if (!car) return null;
  //Error Checking code to ensure that pictures have both a thumbnail and full version sutible for display
  // It will remove any null value
  let imagesSrc =
    car && car["images"]
      ? car.images.map((n) =>
          n.url_thumbnail == null ? [] : n.url_full == null ? [] : [n]
        )
      : [];

  //Flatten evImgs into a single array
  const images = [].concat.apply([], imagesSrc);

  let carImages = images.map((image) => {
    return {
      original: image.url_full,
      thumbnail: image.url_thumbnail,
      originalAlt: image.alt,
      thumbnailAlt: image.title,
    };
  });
  carImages.shift();
  return (
    <>
      <section className="ev-jumbotron">
        <div className="container">
          <div className="row">
            <div className="col">
              <h1 style={{ textAlign: "left" }}>
                {car.model_year} {FormatCarName(car)}
              </h1>
            </div>
          </div>

          <div className="row">
            <div className="col-sm-12 col-md-7 col-lg-8">
              <div className="text-center offset-lg-1 col-lg-10 col-md-12">
                <VehicleImage image={images[0]} size="full" alt={FormatCarName(car)} />
              </div>
            </div>
            <div
              className="col-sm-12 col-md-5 col-lg-4"
              style={{ margin: "auto" }}
            >
              <UpcomingPricePanel car={car} paymentDetails={paymentDetails} />
            </div>
          </div>
        </div>
      </section>
      <section className="container upcoming-ev-price-details">
        <div className="row">
          <div className="main">
            <UpcomingCarDetails car={car} />
            <br />
          </div>
        </div>
        <br />
      </section>
    </>
  );
};

export default UpcomingEVJumbotron;

UpcomingEVJumbotron.propTypes = {
  car: PropTypes.object,
  paymentDetails: PropTypes.object,
};
