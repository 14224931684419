import React from "react";
// import { Link } from "react-router-dom";
import "./Footer.scss";
// import SmoothScroll from "../../../../utils/Helpers/SmoothScroll"
// import {FormattedMessage} from 'react-intl';
import DisclaimerComponent from "@zappy-ride/disclaimer-footer-component";
const Footer = ({ language, changeLanguage }) => {

  // const languageOptions = () => {
  //        return (
  //        <>
  //               <option
  //                      value={'ES'}
  //                      key={'ES'}
  //               >
  //                      {'Spanish'}
  //               </option>
  //        </>
  //        )
  // }

  // const onChangeLanguage = e => {
  //        changeLanguage(e.target.value)
  //        SmoothScroll("root")
  // }

  return (
    <div className="Footer">
      <div className="container">
        <div className="row">
          <div className="footer-col">
            <div className="footer-logo">
              <img src="https://prod.tva-ers.media.energyright.com/wp-content/uploads/2019/09/logo-300x49.png" alt="EnergyRight Logo" />
            </div>
          </div>
          <div className="footer-col">
            <ul id="menu-footer-menu-1" className="menu">
              <li id="menu-item-380" className="menu-item menu-item-type-custom menu-item-object-custom menu-item-380"><a target="_blank" rel="noopener noreferrer" href="https://www.tva.com/employees-and-retirees">Employees &amp; Retirees</a></li>
              <li id="menu-item-381" className="menu-item menu-item-type-custom menu-item-object-custom menu-item-381"><a target="_blank" rel="noopener noreferrer" href="https://www.tvakids.com/">TVA Kids</a></li>
              <li id="menu-item-383" className="menu-item menu-item-type-custom menu-item-object-custom menu-item-383"><a target="_blank" rel="noopener noreferrer" href="https://www.tva.com/Information/Doing-Business-with-TVA">Doing Business With TVA</a></li>
              <li id="menu-item-384" className="menu-item menu-item-type-custom menu-item-object-custom menu-item-384"><a target="_blank" rel="noopener noreferrer" href="https://www.tva.com/Information/TVA-Privacy-Policy">TVA Privacy Policy</a></li>
              <li id="menu-item-392" className="menu-item menu-item-type-custom menu-item-object-custom menu-item-392"><a target="_blank" rel="noopener noreferrer" href="https://www.tva.com/Information/Freedom-of-Information">Freedom of Information Act</a></li>
              <li id="menu-item-393" className="menu-item menu-item-type-custom menu-item-object-custom menu-item-393"><a target="_blank" rel="noopener noreferrer" href="https://www.tva.com/Information/Important-Information-Regarding-the-Use-of-This-TVA-Web-Site">Legal Notices</a></li>
              <li id="menu-item-394" className="menu-item menu-item-type-custom menu-item-object-custom menu-item-394"><a target="_blank" rel="noopener noreferrer" href="https://www.tva.com/Information/Information-Quality/">Information Quality</a></li>
              <li id="menu-item-395" className="menu-item menu-item-type-custom menu-item-object-custom menu-item-395"><a target="_blank" rel="noopener noreferrer" href="https://www.tva.com/Information/NO-FEAR-Act-Data/">No Fear Act Data</a></li>
              <li id="menu-item-396" className="menu-item menu-item-type-custom menu-item-object-custom menu-item-396"><a target="_blank" rel="noopener noreferrer" href="https://www.tva.gov/Information/Equal-Employment-Opportunity-Policy">Equal Employment Opportunity Policy</a></li>
              <li id="menu-item-397" className="menu-item menu-item-type-custom menu-item-object-custom menu-item-397"><a target="_blank" rel="noopener noreferrer" href="https://www.tva.com/Information/TVA-Accessibility">Accessibility Information</a></li>
              <li id="menu-item-398" className="menu-item menu-item-type-custom menu-item-object-custom menu-item-398"><a target="_blank" rel="noopener noreferrer" href="http://oig.tva.gov/">Inspector General</a></li>
              <li id="menu-item-399" className="menu-item menu-item-type-custom menu-item-object-custom menu-item-399"><a target="_blank" rel="noopener noreferrer" href="https://www.tva.gov/About-TVA/Police">TVA Police</a></li>
            </ul>
          </div>
          <div className="footer-col">
            <ul id="menu-footer-menu-2" className="menu">
              <li id="menu-item-382" className="menu-item menu-item-type-custom menu-item-object-custom menu-item-382"><a target="_blank" rel="noopener noreferrer" href="https://www.tva.com/Energy">Energy</a></li>
              <li id="menu-item-385" className="menu-item menu-item-type-custom menu-item-object-custom menu-item-385"><a target="_blank" rel="noopener noreferrer" href="https://www.tva.com/Environment">Environment</a></li>
              <li id="menu-item-386" className="menu-item menu-item-type-custom menu-item-object-custom menu-item-386"><a target="_blank" rel="noopener noreferrer" href="https://www.tva.com/Economic-Development">Economic Development</a></li>
              <li id="menu-item-387" className="menu-item menu-item-type-custom menu-item-object-custom menu-item-387"><a target="_blank" rel="noopener noreferrer" href="https://www.tva.com/Newsroom">Newsroom</a></li>
              <li id="menu-item-388" className="menu-item menu-item-type-custom menu-item-object-custom menu-item-388"><a target="_blank" rel="noopener noreferrer" href="https://www.tva.com/Careers">Careers</a></li>
              <li id="menu-item-389" className="menu-item menu-item-type-custom menu-item-object-custom menu-item-389"><a target="_blank" rel="noopener noreferrer" href="https://www.tva.com/About-TVA">About</a></li>
              <li id="menu-item-390" className="menu-item menu-item-type-custom menu-item-object-custom menu-item-390"><a target="_blank" rel="noopener noreferrer" href="https://www.snl.com/IRW/CorporateProfile/4063363">Investor Relations</a></li>
              <li id="menu-item-391" className="menu-item menu-item-type-custom menu-item-object-custom menu-item-391"><a target="_blank" rel="noopener noreferrer" href="https://www.tva.gov/Energy/Transmission-System/Staying-Safe-Around-Transmission-Lines-and-Equipment">Safety</a></li>
              <li id="menu-item-3059" className="menu-item menu-item-type-custom menu-item-object-custom menu-item-3059"><a href="https://highlights.energyright.com/">FY22 Highlights Report</a></li>
              <li id="menu-item-3057" className="menu-item menu-item-type-custom menu-item-object-custom menu-item-3057"><a href="https://portal.assets.site/24406">EnergyRight Marketing Hub</a></li>
            </ul>
          </div>
          <div className="footer-col">
            <h6>Follow TVA</h6>
            <ul className="social">
              <li>
                <a href="https://www.facebook.com/TVA" target="blank">
                  <img alt="Facebook" src="https://energyright.com/wp-content/themes/tva-ers/inc/assets/images/facebook.svg" />
                </a>
              </li>
              <li>
                <a href="https://twitter.com/tvanews" target="blank">
                  <img alt="Twitter" src="https://energyright.com/wp-content/themes/tva-ers/inc/assets/images/twitter.svg" />
                </a>
              </li>
              <li>
                <a href="https://www.linkedin.com/company/tva" target="blank">
                  <img alt="LinkedIn" src="https://energyright.com/wp-content/themes/tva-ers/inc/assets/images/linkedin.svg" />
                </a>
              </li>
              <li>
                <a href="https://www.instagram.com/tva" target="blank">
                  <img alt="Instagram" src="https://energyright.com/wp-content/themes/tva-ers/inc/assets/images/instagram.svg" />
                </a>
              </li>
              <li>
                <a href="https://www.flickr.com/photos/tennesseevalleyauthority/" target="blank">
                  <img alt="Flickr" src="https://energyright.com/wp-content/themes/tva-ers/inc/assets/images/flickr.svg" />
                </a>
              </li>
              <li>
                <a href="https://www.youtube.com/user/TVANewsVideo" target="blank">
                  <img alt="YouTube" src="https://energyright.com/wp-content/themes/tva-ers/inc/assets/images/youtube.svg" />
                </a>
              </li>
            </ul>
            <h6>Follow EnergyRight</h6>
            <ul className="social">
              <li>
                <a href="https://www.facebook.com/TVAEnergyRight/" target="blank">
                  <img alt="Facebook" src="https://energyright.com/wp-content/themes/tva-ers/inc/assets/images/facebook.svg" />
                </a>
              </li>
              <li>
                <a href="https://www.youtube.com/channel/UC3PFgW6WInXBa9xvA0P0dZw" target="blank">
                  <img alt="YouTube" src="https://energyright.com/wp-content/themes/tva-ers/inc/assets/images/youtube.svg" />
                </a>
              </li>
            </ul>
          </div>
          <div className="footer-col">
            <h6 className="widget-title">Tennessee Valley Authority</h6>
            <div className="textwidget"><p>400 West Summit Hill Drive<br />Knoxville, TN 37902<br />(865) 632-2101<br />tvainfo@tva.com</p></div>
          </div>
        </div>
      </div>
      {/* <p style={{ textAlign: "center", color: "white" }}>
        <span style={{ fontFamily: "Arial, Helvetica" }}> &copy;
          {(new Date().getFullYear().toString()) + " "} J.D. Power ZappyRide (c). {" "}
          <a style={{color: "white"}} rel="noopener noreferrer" target="_blank" href="https://zappyride.com/legal-notices">Legal notices.</a>
        </span>
      </p> */}
      <div className="disclaimer_container container">
      
        <DisclaimerComponent clientName="zappynobackground"/>
       
      </div>

    </div>
  );
};

export default Footer;
